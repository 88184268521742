.reliant-pink{
    color: #EC1795;
}

.reliant-blue{
    color: #00AEEF;
}

.reliant-purple{
    color: #333092;
}
