.table-wrapper {
    padding-bottom: 20px;
}

td .actionButtons{
    text-align: center;
    justify-content: center;

    button {
        background: none;
        color: black;
        width: auto !important;
    }

    .actionButtons {
        width: 60px;
        justify-content: center;
    }

    .selectIndividual {
        width: -1px;
    }

}

td, tr {
    text-align: center;
}

th, td {
    width: 22.5%;
}

th:first-of-type, td:first-of-type {
    width:10%;
}

.searchbar {
    float: right;
    margin-top: 20px;
    min-width: 350px;
}

.pagination .page-item.active .page-link {
    background-color: #cd007a;
    border: 1px solid #cd007a;
}

.page-link{
    color: #cd007a;
}

.showingLabel{
    position: absolute;
    display: block;
    height: 60px;
}