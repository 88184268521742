@use "../../App.scss" as app;

.hero {
    // background new url
    background-image: url("../../img/heroBanner.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;

    h1 {
        color: #00aeef;
    }
}

@media (min-width: 768px) {
    .hero {
        background-size: cover;
        height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.faqBoundary {
    border: 1px solid pink;
}

.containerFaqs {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    h2 {
        margin: 1.5rem 0;
    }
}

.lineFaqs {
    border-bottom: 1px solid #4a4a4a;
}

.customer-cta {
    display: flex;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 40px;
    border: 1px solid #707070;
    border-radius: 12px;

    p {
        font-size: 12px;
    }

    .customer {
        text-align: center;
        padding: 20px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        // position: relative;
        // border: 2px solid blue !important;

        .submit {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border: 2px solid green;

            .submit p {
                padding-top: 1rem;
                padding: 0rem;
            }

            @media screen and (min-width: 992px) {
                bottom: -11px;
                width: 85%;
                position: relative;
                left: 30px;
                // border: 2px solid blue !important;
            }
        }

        .submit-container {
            // border: 2px solid red;
            margin-top: 1rem;

            p {
                padding-top: 1rem;
            }
        }

        a {
            text-decoration: none !important;
        }
    }

    .login {
        text-align: center;
        padding: 20px;
        margin: 0 auto;

        label,
        span {
            font-family: "Effra";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #4a4a4a;
            padding: 5px;
        }

        .submit {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .not-customer {
        // border: 2px solid pink !important;
        .submit {
            // border: 2px solid purple !important;

            @media screen and (min-width: 992px) {
                bottom: -29px;
                width: 85%;
                position: relative;
                left: 30px;
                // border: 2px solid blue !important;
            }
        }
    }

    .vr {
        color: black;
        opacity: 0.75;
        padding: 0.5px;
    }

    hr {
        color: black;
        opacity: 0.75;
        padding: 0.5px;
    }
}

.not-customer {
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    a {
        text-decoration: none !important;
    }

    .submit {
        bottom: -83px;
        width: 85%;
        position: relative;
        left: 30px;

        button {
            margin: 0 auto;
        }
        @media screen and (max-width: 992px) {
            // border: 2px solid blue !important;
            bottom: 0px;
            width: 100%;
            left: 0px;
        }
    }
}

.companySelect {
    width: 70%;
}

.errorText {
    font-size: 15px !important;
    color: red;
    font-weight: 500;
}

.noBorder {
    border: none !important;
}

button,
input[type="submit"] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border: none;
    height: 40px;
    background: #cd007a;
    border-radius: 2rem;
    color: white;

    svg {
        margin-right: 5px;
        font-size: 20px;
    }
}

button:disabled,
input[type="submit"]:disabled {
    opacity: 0.3;
}

button.secondary {
    color: #cd007a;
    background-color: white;
    border: 1px solid #cd007a;
    border-radius: 2rem;
}

.terms {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;
    margin-top: 30px;

    p {
        font-size: 12px;
    }

    @media screen and (max-width: 992px) {
        // border: 2px solid blue !important;
        padding-left: 1rem;
    }
}

.terms:last-of-type {
    margin-bottom: 40px;
}

.textAlignCenter {
    text-align: center;
}

.terms .MuiButtonBase-root.MuiAccordionSummary-root,
.terms .MuiAccordionDetails-root {
    padding: 0;
}

.terms .MuiAccordionSummary-content {
    flex-grow: 0;
    justify-content: left;
}
